.footer {
  padding: 3em 0;
  margin-top: 4em;
  text-align: center;
}

.footer__link {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--clr-fg);
}

@media (max-width: 600px) {
  .footer {
    padding: 2em;
    margin-top: 3em;
  }
}

.credits {
  /*font-family: "truenolight";*/
  letter-spacing: 1px;
  font-size: 11px;
  /*color: #fff;*/
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}
