.motion {
  padding: 0.4em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.motion:hover {
  transform: translateY(-12px);
}

.motion__description {
  margin-top: 0.2em;
}

.motion__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.2em 0;
}

.motion__stack-item {
  margin: 0.4em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.motion .link--icon {
  margin-left: 0.5em;
}