* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
  font-family: 'truenoreg';
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
  font-family: 'truenobold';
}

h2 {
  font-size: 2rem;
  font-family: 'truenobdit';
}

h3 {
  font-size: 1.5rem;
  font-family: 'truenoblk';
}

h4 {
  font-size: 1.1rem;
  font-family: 'truenoreg';
  font-weight: bold;
  text-decoration: underline;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

p {
  font-family: 'truenoreg';
  font-size: 14px;
  padding: 0.2em;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

@font-face {
  font-family: 'truenobold';
  src: local('truenobold'),
    url('./fonts/truenobd-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'truenobdit';
  src: local('truenobdit'),
    url('./fonts/truenobdit-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'truenoblk';
  src: local('truenoblk'),
    url('./fonts/truenoblk-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'truenolt';
  src: local('truenolt'),
    url('./fonts/truenolt-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'truenoreg';
  src: local('truenoreg'),
    url('./fonts/trueno-reg.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


