.about {
  flex-direction: column;
  padding: 0px;
}

.about__name {
  color: var(--clr-primary);
  font-size: 0.8em;
}

.about__role {
  padding: 0px;
}

.about__desc {
  font-size: 1rem;
  max-width: 800px;
  padding: 0px;
}

.about__desc,
.about__contact {
  padding: 0 0 20px 0;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}

.semi-title {
  font-size: 16px;
  padding: 0%;
  font-family: 'truenoreg';
}

.no-pads {
  padding: 0px;
}
